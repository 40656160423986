import { createAction } from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const setAmazonUrl = createAction('shop/url');

export const searchAmazon = (query, page, sort) => {
    return async (dispatch, getState) => {
        const store = getState();

        try {
            const params = {
                sort,
            }

            if(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(query)) {
                params.url = query;
            } else {
                params.search_term = query;
                params.amazon_domain = store?.shop?.amazonUrl?.value
                params.page = page;
            }
            const res = await api.search(params);

            if(res) {
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            return false;
        }
    }
}

export const getProduct = (asin) => {
    return async (dispatch, getState) => {
        const store = getState();

        try {
            const params = {
                asin,
                amazon_domain: store?.shop?.amazonUrl?.value,
            }
            const res = await api.product(params);

            if(res) {
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            return false;
        }
    }
}
export const getHome = () => {
    return async (dispatch) => {
        try {
            const res = await api.home();

            if(res) {
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            return false;
        }
    }
}
