import styled from "styled-components";
import { Button as BSButton } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {useCallback, useMemo, useRef, useState} from "react";
import SVG from "react-inlinesvg";

import {accountRoutes, adminRoutes} from "../../constants";
import {useDispatch} from "react-redux";
import {logout} from "../../states/admin/actions";
import {logout as userLogout} from "../../states/auth/actions";
import {disconnectWallet} from "../../states/auth/actions";
import useToggle from "../../hooks/useToggle";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  margin-left: ${({ noMargin }) => noMargin ? '0' : '20px'};
`

const Button = styled(BSButton)`
  height: 56px;
  border-radius: ${({ borderRadius, rounded }) => rounded ? '500rem' : borderRadius};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;

  ${({ theme, rounded }) => {
      return rounded ? `
          background-color: transparent;
          color: ${theme.white};
          border: 1px solid ${theme.white}
      ` : ''
  }}
  
`

const DropdownList = styled.div`
	background: ${({ theme }) => theme.elementBG};
	box-shadow: 0 4px 52px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
    backdrop-filter: blur(68px);
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	right: 0;
	top: 68px;
	min-width: 100%;
	padding: 24px;
	transition: 0.5s ease all;
	transform: ${({ isOpen }) => (isOpen ? `translateY(0)` : `translateY(30px)`)};
	opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
	visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  
    @supports not ((-webkit-backdrop-filter:  blur(68px)) or (backdrop-filter: blur(68px))) {
        background-color: ${({ theme }) => theme.popUpBG};
        border: 1px solid ${({ theme }) => theme.elementBG};
        backdrop-filter: none;
    }
`;


const DropdownItem = styled(NavLink)`
	border: none;
	background-color: transparent;
	font-weight: 500;
	font-size: 1rem;
    color: ${({ theme }) => theme.text1};
	white-space: nowrap;
	padding: 0;
    transition: 0.3s ease all;

	@media (max-width: 991px) {
		font-size: 0.875rem;
	}

	&:hover,
	&:focus,
	&:active,
    &.active {
        color: ${({ theme }) => theme.primary};
		outline: none;
		text-decoration: none;
		box-shadow: none;
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}
  
`;

const AccountDropdown = props => {
    const [open, toggle] = useToggle(false);
    const dispatch = useDispatch();
    const node = useRef(null);

    useOnClickOutside(node, open ? toggle : undefined);

    const routes = useMemo(() => {
        return props?.type === 'admin' ? adminRoutes : accountRoutes;
    }, [props?.type])

    const handleAction = (action) => {
        toggle();
        switch(action) {
            case "logout": {
                if(props?.type === 'admin') {
                    dispatch(logout());
                } else {
                    dispatch(userLogout());
                }
                break;
            }
        }
    }

    return (
        <Wrapper ref={node} noMargin={props?.type === 'admin' || props.noMargin}>
            <Button onClick={toggle} borderRadius={props.lessBorderRadius ? "0.375rem" : "1rem"} rounded={props.rounded}>
                <SVG src={require('../../assets/images/navigation/user.svg').default} size={24} style={{ marginRight: 15 }}/>
                <SVG src={require('../../assets/images/dropdown.svg').default} size={24}/>
            </Button>
            <DropdownList isOpen={open}>
                {Object.keys(routes).map(key => {
                    const r = routes[key];

                    return r?.type === 'action' ? (
                        <DropdownItem as={'button'} key={key} onClick={handleAction.bind(this, r?.action)}>
                            {r.title}
                        </DropdownItem>
                    ) : (
                        <DropdownItem to={r?.path} key={key} activeClassName={'active'} onClick={toggle}>
                            {r.title}
                        </DropdownItem>
                    )
                })}
            </DropdownList>
        </Wrapper>
    )
}

export default AccountDropdown;