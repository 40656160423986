import React from 'react';
import logo from '../../../../assets/consumerIcons/logo.svg';
import twitter from '../../../../assets/consumerIcons/twitter.svg';
import telegram from '../../../../assets/consumerIcons/telegram.svg';
import discord from '../../../../assets/consumerIcons/discord.svg';
import snake from '../../../../assets/consumerIcons/snake.svg';
import useNewsletter from "../../../../hooks/useNewsletter";
import LoadingButton from "../../../LoadingButton";

function Footer() {
  const [form, loading] = useNewsletter();
  return (
    <footer className="bg-consumerDark text-white">
      <div
        className="flex flex-row justify-between items-center py-12 px-5.5 lg:px-32
                      lg:border-b border-solid border-consumerGray"
      >
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2 md:mr-20">
            <img className="w-8 pt-2.5" src={logo} alt="logo" />
            <div className="font-consumerBlack text-2xl font-bold">Shopping</div>
          </div>
          <div className="opacity-50">© 2021Shopping</div>
          <div className="flex items-center space-x-4">
            <img src={twitter} alt="twitter" />
            <img src={discord} alt="discord" />
            <img src={telegram} alt="telegram" />
            <img src={snake} alt="snake" />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="">Project</div>
          <div className="opacity-50">About us</div>
          <div className="opacity-50">Team</div>
          <div className="opacity-50">Staking</div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="">Help</div>
          <div className="opacity-50">Contact</div>
          <div className="opacity-50">Trustpilot</div>
          <div className="opacity-50">Telegram</div>
        </div>
        <div className="hidden lg:flex flex-col space-y-6">
          <div className="">
            <div className="">Say up to day</div>
            <div className="opacity-70">Get the lastest updaites for shopping</div>
          </div>
          <form onSubmit={form.handleSubmit}>
            <div className="flex">
              <div className="relative flex h-12 w-full mr-4.5">
                <input
                  type="text"
                  className="border border-solid border-consumerLighterGray bg-transparent
                             py-2.5 px-6 rounded-lg w-full h-full"
                  placeholder="Email"
                  name={"EMAIL"}
                  value={form.values.EMAIL}
                  onChange={form.handleChange}
                />
              </div>
              <LoadingButton
                  type="submit"
                  loading={loading}
                  className="bg-consumerPrimary text-white py-3.25 px-2.5 min-h-0 rounded"
                  style={{ minWidth: '87px', height: "auto", minHeight: "initial" }}
              >
                Subscribe
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
      <div className="lg:hidden
          flex
          flex-col
          items-center
          space-y-6
          pb-12
          border-b
          border-solid
          border-consumerGray"
      >
        <div className="">
          <div className="">Say up to day</div>
          <div className="opacity-70">Get the lastest updaites for shopping</div>
        </div>
        <form onSubmit={form.handleSubmit}>
          <div className="flex">
            <div className="relative flex h-12 w-full mr-4.5">
              <input
                  type="text"
                  className="border border-solid border-consumerLighterGray bg-transparent
                           py-2.5 px-6 rounded-lg w-full h-full"
                  placeholder="Email"
                  id={"EMAIL"}
                  name={"EMAIL"}
                  value={form.values.EMAIL}
                  onChange={form.handleChange}
              />
            </div>
            <LoadingButton
                type="submit"
                loading={loading}
                className="bg-consumerPrimary text-white py-3.25 px-2.5 min-h-0 rounded"
                style={{ minWidth: '87px', height: "auto", minHeight: "initial" }}
            >
              Subscribe
            </LoadingButton>
          </div>
        </form>
      </div>
      <div className="flex justify-between flex-col md:flex-row py-12 px-5.5 lg:px-32">
        <div className="mb-8 md:mb-0">Shopping DAPP LTD</div>
        <div className="flex flex-col md:flex-row">
          <div className="opacity-50 mr-0 md:mr-4.5 mb-3.25 md:mb-0">Business Mailing Address:</div>
          <div className="">Singapore, Golden House 3, 73889 Singapore.</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
