import styled from "styled-components";
import { Modal as BSModal } from "react-bootstrap";

export const Modal = styled(BSModal)`
	.modal-content {
		background: ${({ theme }) => theme.modalBG};
		backdrop-filter: blur(8px);
		border-radius: 18px;
		color: ${({ theme }) => theme.text1};
	}

	.modal-header {
		border: none;
		font-size: 1.25rem;
		font-weight: 700;
	}

	.modal-title {
		font-size: 1.25rem;
	}

	.modal-header .close {
		display: none;
	}
`;