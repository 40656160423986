import {Route, Switch, Redirect} from "react-router-dom";
import {Suspense, lazy} from "react";

import CartLoading from "./Loading";
import {useAuthentication} from "../../states/auth/hooks";
import {useSelector} from "react-redux";

const DiscountPage = lazy(() => import('../../pages/Cart/Discount'));
const ShippingPage = lazy(() => import('../../pages/Cart/Shipping'));
const ReviewPage = lazy(() => import('../../pages/Cart/Review'));
const PaymentPage = lazy(() => import('../../pages/Cart/Payment'));
const OrderStatus = lazy(() => import('../../pages/Cart/PaymentStatus'));

const CartPage = props => {
    const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn);
    return (
        <Suspense fallback={<CartLoading/>}>
            {isLoggedIn ? (
                <Switch>
                    <Route path={'/cart/payment/:orderId'} component={OrderStatus}/>
                    <Route path={'/cart/payment'} component={PaymentPage}/>
                    <Route path={'/cart/review'} component={ReviewPage}/>
                    <Route path={'/cart/shipping'} component={ShippingPage}/>
                    <Route path={'/cart'} component={DiscountPage}/>
                </Switch>
            ) : (
                <Redirect to={'/login'}/>
            )}
        </Suspense>
    )
}

export default CartPage;