import styled from "styled-components";
import SVG from "react-inlinesvg";

import Logo from "../Logo";
import Navigation from "./Navigation";
import { useState } from "react";
import SideDrawer from "../SideDrawer";
import ShoppingNavigation from "./Navigation/shopping";
import {useRouteMatch} from "react-router-dom";

const Container = styled.div`
	width: 100%;
	height: 110px;
	padding: 34px 0 34px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 991px) {
		padding: 24px 0;
		height: 80px;
		margin-bottom: 40px;
	}
`;

const Wrapper = styled.div`
  background-color: white;
    box-shadow: ${({ isHome }) => isHome ? 'none' : '0 8px 12px rgba(0, 0, 0, 0.03)'};
`
const SideDrawerButton = styled.div`
	cursor: pointer;
	color: ${({ theme }) => theme.text1};
`;

const Header = (props) => {
    const router = useRouteMatch()
    let isHome = false;
    if(router.path === '/' && router.isExact) {
        isHome = true;
    }
    const [sideDrawer, setSideDrawer] = useState(false);
    const dismissHandler = () => {
        setSideDrawer(false);
    };

    return (
        <Wrapper isHome={isHome}>
            <div  className={'container'}>
                <SideDrawer open={sideDrawer} onDismiss={dismissHandler} />
                <Container>
                    <SideDrawerButton className="d-flex d-lg-none">
                        <SVG
                            src={require("../../../assets/images/global/menu.svg").default}
                            onClick={() => setSideDrawer(true)}
                        />
                    </SideDrawerButton>
                    <Logo light />
                    <ShoppingNavigation />
                </Container>
            </div>
        </Wrapper>
    );
};

export default Header;
