import React from 'react';
import MiniProductCard from './MiniProductCard';
import ArrowSlider from '../UI/ArrowSlider';
import arrow from '../../../../assets/consumerIcons/arrowWhite.svg';

export default function ProductsMinimalSlider({ title, loading = false, data = [] }) {
	return (loading || data.length > 0) && (
		<div className="mt-16 lg:mt-20 h-80">
			<div className="flex justify-between items-center container mb-12">
				<h1 className="text-xl md:text-4xl">{title}</h1>
				<div className="flex items-center">
					<div className="mr-4.5 cursor-pointer font-bold text-sm md:text-base">
						View more products
					</div>
					<img src={arrow} alt="arrow" />
				</div>
			</div>
			<div className="relative">
				<div
					className="hidden xl:block absolute top-0 -left-8 z-10
                        bg-gradient-to-r from-white w-56 h-full"
				/>
				{loading ? (
					<ArrowSlider>
						{[...Array(20)].map((_, i) => {
							return (
								<MiniProductCard wrapperClassName="mx-3.25" loading={true} />
							)
						})}
					</ArrowSlider>
				) : (
					<ArrowSlider>
						{data.map((product) => {
							return (
								<MiniProductCard wrapperClassName="mx-3.25" product={product} key={product?.asin} />
							)
						})}
					</ArrowSlider>
				)}
				<div
					className="hidden xl:block absolute top-0 -right-8 z-10
                        bg-gradient-to-l from-white w-56 h-full"
				/>
			</div>
		</div>
	);
}
