import { createAction } from '@reduxjs/toolkit';
import api from "../../http/api";
import {toast} from "react-hot-toast";

export const updateLogin = createAction('admin/login', (accessToken, admin) => {
    return {
        payload: {
            accessToken,
            admin,
        }
    }
});

export const updateLogout = createAction('admin/logout');


export const login = (email, password) => {
    return async dispatch => {
        try {
            const res = await api.adminLogin({
                identifier: email,
                password,
            })

            if(res && res?.jwt) {
                if(res?.user?.role?.name !== 'Admin') {
                    toast.error("You have not permission to this action");
                    return false;
                }

                toast.success("Logged in successfully");
                dispatch(updateLogin(res?.jwt, res?.user));

                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const logout = () => {
    return async dispatch => {
        try {
            toast.success("Logged out successfully");
            dispatch(updateLogout());
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}
