import axios from 'axios';
import {useFormik} from "formik";
import useAsyncLoading from "./useAsyncLoading";
import toast from "react-hot-toast";


const useNewsletter = () => {
	const [loading, handleAsync] = useAsyncLoading(async (values) => {
		try {
			const res = await axios.post(process.env.REACT_APP_NEWSLETTER_SUBSCRIBE_URL, values);
			if(res) {
				toast.success("Thank you, you have successfully registered!");
			}
		} catch(e) {
			toast.error(e?.response?.data?.message || "Something went wrong.");
		}
	})
	const formik = useFormik({
		initialValues: {
			EMAIL: "",
		},
		onSubmit: async values => {
			await handleAsync(values);
		}
	})


	return [formik, loading];
}

export default useNewsletter;
