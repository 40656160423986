import styled from "styled-components";
import { useRouteMatch } from 'react-router-dom'

import Header from "../Header/Shopping";
import Footer from "../Footer";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
    align-items: stretch;
	min-height: 100vh;
    background-color: ${({ theme }) => theme.bodyBG};
`;


const Content = styled.div`
	display: flex;
	flex: 1;
    flex-direction: column;
    align-items: stretch;
`;


const ShoppingLayout = props => {
    return (
        <Wrapper>
            <Header />
            <div className="container flex-grow-1">
                <Content>
                    {props.children}
                </Content>
            </div>
            <Footer/>
        </Wrapper>
    )
}

export default ShoppingLayout;