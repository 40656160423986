import { Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import {NavLink, Redirect, Route, Switch, useLocation} from "react-router-dom";
import {useEffect, useState, Suspense, lazy} from "react";

import ShoppingPage from "../Global/Page/shopping";
import {accountRoutes} from "../../constants";
import AccountLoading from "./Loading";
import {useSelector} from "react-redux";

const AccountInfo = lazy(() => import('../../pages/Account/AccountInfo'));
const Limits = lazy(() => import('../../pages/Account/Limits'));
const Addresses = lazy(() => import('../../pages/Account/Addresses'));
const EditAddress = lazy(() => import('../../pages/Account/EditAddress'));
const NewAddress = lazy(() => import('../../pages/Account/NewAddress'));
const TransactionHistory = lazy(() => import('../../pages/Account/TransactionHistory'));

const Button = styled(NavLink)`
  height: 56px;
  margin-bottom: 20px;
  text-decoration: none;
  border: none;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  transition: 0.3s ease all;
  
  &:hover,
  &:active,
  &:focus,
  &.active {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primary};
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
`

const defaultBreadcrumb = [
    {
        title: "My Account",
        url: "/account"
    }
]

const AccountPage = props => {
    const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumb)
    const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn);
    const route = useLocation()

    useEffect(() => {
        const selectedRoute = Object.entries(accountRoutes).find(item => {
            return route?.pathname?.indexOf(item?.[1]?.path) > -1;
        })
        if(selectedRoute) {
            setBreadcrumb(defaultBreadcrumb.concat({
                title: selectedRoute?.[1]?.title,
                url: selectedRoute?.[1]?.path
            }))

        } else {
            setBreadcrumb(defaultBreadcrumb);
        }
    }, [route])

    return isLoggedIn ? (
        <ShoppingPage
            withSearchbar={true}
            breadcrumb={breadcrumb}
        >
            <Row className={'custom-row'}>
                <Col xs={12} lg={3}>

                    {Object.keys(accountRoutes).map(key => {
                        const r = accountRoutes[key];

                        return r?.path && (
                            <Button to={r?.path} key={`account-${key}`} activeClassName={'active'}>
                                {r.title}
                            </Button>
                        )
                    })}
                </Col>
                <Col xs={12} lg={9}>
                    <Suspense fallback={<AccountLoading/>}>
                        <Switch>
                            <Route path={'/account/profile'} component={AccountInfo}/>
                            <Route path={'/account/limit'} component={Limits}/>
                            <Route path={'/account/transactions'} component={TransactionHistory}/>
                            <Route path={'/account/addresses/new'} component={NewAddress}/>
                            <Route path={'/account/addresses/:id/edit'} component={EditAddress}/>
                            <Route path={'/account/addresses'} component={Addresses}/>
                            <Redirect from={'/account'} to={"/account/profile"}/>
                        </Switch>
                    </Suspense>
                </Col>
            </Row>
        </ShoppingPage>
    ) : (
        <Redirect to={'/login'}/>
    )
}

export default AccountPage;