/*eslint-disable*/
import React from 'react';
import signup from '../../../../assets/consumerIcons/signup.svg';
import choose from '../../../../assets/consumerIcons/choose.svg';
import checkout from '../../../../assets/consumerIcons/checkout.svg';
import cart from '../../../../assets/consumerIcons/cart.svg';
import SwipeSlider from '../UI/SwipeSlider';
import * as Scroll from "react-scroll";
import { Link } from 'react-router-dom';

const HashLink = Scroll.Link;

export default function Process() {
  const Items = () => (
    <div className={'flex flex-col md:flex-row items-center md:items-center'}>
      <div className="flex pt-12 md:pt-0 flex-col justify-between justify-between items-center space-y-4 mt-4.5">
        <div className="mb-4.5">
          <div className="w-14 p-2.5 bg-consumerPrimary rounded-full">
            <img className="signup" src={signup} alt="" />
          </div>
        </div>
        <div className="text-center text-xl font-bold">Sign Up</div>
        <div className="text-consumerBlack opacity-40 text-center">
          <div className="">First, Sign up on</div>
          <div className="">shopping Dapp</div>
        </div>
        <Link to={'/connect'}  className="bg-consumerPrimary text-white p-3.25 rounded">
          Sign Up
        </Link>
      </div>
      <div className="flex pt-18 md:pt-0 flex-col justify-between items-center space-y-4 mt-4.5">
        <div className="mb-4.5">
          <div className="w-14 p-2 bg-consumerPrimary rounded-full">
            <img className="" src={choose} alt="choose" />
          </div>
        </div>
        <div className="text-center text-xl font-bold">Choose Products</div>
        <div className="text-consumerBlack opacity-40 text-center w-3/5">
          Second, Select the products that you like to buy
        </div>
        <HashLink to='browse' smooth={true} className="border-1 bg-transparent hover:border-consumerPrimary border-solid border-consumerBlack p-2.75 rounded">
          Search product
        </HashLink>
      </div>
      <div className="flex pt-18 md:pt-0 flex-col justify-between items-center space-y-4 mt-4.5">
        <div className="mb-4.5">
          <div className="w-14 p-2.5 bg-consumerPrimary rounded-full">
            <img className="" src={cart} alt="cart" />
          </div>
        </div>
        <div className="text-center text-xl font-bold">Add to Cart</div>
        <div className="text-consumerBlack opacity-40 text-center w-3/5">
          Third, add products into your cart
        </div>

        <HashLink to='browse' smooth={true} className="border-1 bg-transparent hover:border-consumerPrimary border-solid border-consumerBlack p-2.75 rounded">
          Start
        </HashLink>
      </div>
      <div className="flex pt-18 md:pt-0 flex-col items-center space-y-4">
        <img className="w-26" src={checkout} alt="checkout" />
        <div className="text-center text-xl font-bold">Check Out</div>
        <div className="text-consumerBlack opacity-40 text-center w-3/5">
          Pay with your favorite cryptocurrency coin
        </div>
        <HashLink to='browse' smooth={true} className="border-1 bg-transparent hover:border-consumerPrimary border-solid border-consumerBlack p-2.75 rounded">
          Start
        </HashLink>
      </div>
    </div>
  );

  return (
    <div>
      <h1 className="text-4xl text-center font-bold">The process</h1>
      <div className="mt-24">
        <div className="px-16 pr-17 lg:px-20 lg:pr-28">
          <hr className="dotted w-full mx-auto hidden md:block" />
        </div>
        <div className="-mt-20">
          <div className="flex md:justify-between px-2.5 mt-8">
            <Items />
          </div>
        </div>
      </div>
    </div>
  );
}
