import styled from "styled-components";
import SVG from 'react-inlinesvg';

import { InputGroupFormControl as FormControl, InputGroup, InputGroupText, InputGroupPrepend } from "../Form";
import SearchIcon from "../../assets/images/search.svg";
import {Button as BSButton} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import * as url from "url";
import AmazonSelector from "../AmazonSelector";
import useParsedQueryString from "../../hooks/useParsedQueryString";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
    border: ${({ isHome }) => isHome ? 'none' : '1px solid #DEE0E3'};
    box-shadow: ${({ isHome }) => isHome ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.05)'};
  background-color: ${({ theme , isHome }) => isHome ? theme.secondBG : theme.elementBG};
  border-radius: 25px;
    margin-top: 30px;
  
  
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    border-radius: 1rem;
  }
`

const El = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  
  @media (min-width: 992px) {

    &:not(:last-child) {
      padding-right: 30px;
      margin-bottom: 0;
    }
  }
`

const Button = styled(BSButton)`
  height: 56px;
  min-width: 176px;
`

const HeaderSearchbar = props => {
    const router = useRouteMatch()
    let isHome = false;
    if(router.path === '/' && router.isExact) {
        isHome = true;
    }
    const queryParams = useParsedQueryString()
    const [query, setQuery] = useState("");
    const history = useHistory();
    const match = useRouteMatch()

    const searchHandler = () => {
        if(query.length !== 0) {
            const urlParams = new URLSearchParams("");
            urlParams.set("query", query?.trim());
            history.push(`/search?${urlParams.toString()}`)
        }
    }

    useEffect(() => {
        if(match?.path.indexOf('/search') === 0 && queryParams?.query) {
            setQuery(queryParams?.query);
        }
    }, [match, queryParams])

    return (
        <Wrapper isHome={isHome}>
            <El>
                <AmazonSelector
                    variant={'primary'}
                />
            </El>
            <El className="flex-grow-1">
                <InputGroup className={"w-auto"} bg={"darker"}>
                    <InputGroupPrepend>
                        <InputGroupText>
                            <SVG src={SearchIcon} />
                        </InputGroupText>
                    </InputGroupPrepend>
                    <FormControl
                        value={query}
                        id="header-search"
                        placeholder={"Search"}
                        onChange={e => setQuery(e.target.value)}
                        onKeyDown={e => e.key === "Enter" && searchHandler()}
                    />
                </InputGroup>
            </El>
            <El>
                <Button variant={'primary'} onClick={searchHandler}>Search</Button>
            </El>
        </Wrapper>
    )
}

export default HeaderSearchbar;