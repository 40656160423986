import styled from "styled-components";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import HeaderSearchbar from "../../HeaderSearchbar";

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	@media (max-width: 991px) {
		padding-bottom: 0;
	}
`;


const Breadcrumb = styled.div`
	display: flex;
	align-items: center;
    padding: 30px 0 0;
`;

const Separator = styled.span`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.text3};
	padding-right: 6px;

	@media (max-width: 991px) {
		font-size: 0.75rem;
		padding-right: 10px;
	}
`;

const BreadcrumbItem = styled(Link)`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.text3};
	padding-right: 6px;

	@media (max-width: 991px) {
		font-size: 0.75rem;
		padding-right: 10px;
	}
  
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.primary};
    }
`;

const LastBreadcrumb = styled.span`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.text1};

	@media (max-width: 991px) {
		font-size: 0.75rem;
		padding-right: 10px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
    padding-top: ${({ hasTitle }) => hasTitle ? '24px' : "30px"};
`;


const Title = styled.h2`
  margin-top: 30px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.text1};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`

const ShoppingPage = ({ breadcrumb, withSearchbar, ...props }) => {
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, []);

    return (
        <PageContainer>
            {withSearchbar && (
                <HeaderSearchbar />
            )}
            {breadcrumb && (
                <Breadcrumb>
                    {breadcrumb.map((item, _i) => {
                        if (_i === breadcrumb.length - 1) {
                            return <LastBreadcrumb>{item.title}</LastBreadcrumb>;
                        } else {
                            return (
                                <>
                                    <BreadcrumbItem to={item.url}>{item.title}</BreadcrumbItem>
                                    <Separator>/</Separator>
                                </>
                            );
                        }
                    })}
                </Breadcrumb>
            )}
            {props?.title && (<Title>{props?.title}</Title>)}
            <Content hasTitle={props?.title}>{props.children}</Content>
        </PageContainer>
    );
};

export default ShoppingPage;
