import {useEffect} from "react";

import {useActiveWeb3React} from "../../hooks";
import {useAuthentication} from "./hooks";
import {useDispatch, useSelector} from "react-redux";
import {disconnectWallet} from "./actions";

export default function Updater() {
    const dispatch = useDispatch();
    const { account } = useActiveWeb3React();
    const accessToken = useSelector(state => state.auth.accessToken);
    const address = useSelector(state => state.auth.account);
    const [isSignedWallet, handleAuthorization] = useAuthentication()

    useEffect(() => {
        if(account?.toLowerCase() !== address?.toLowerCase()) {
            console.log('run', address, account)
            dispatch(disconnectWallet())
        }
    }, [account])

    useEffect(() => {
        if(!isSignedWallet) {
            handleAuthorization(accessToken)
        }
    }, [account, isSignedWallet, accessToken])

    return null
}