/*eslint-disable*/
import React from 'react';
import styled from "styled-components";

import watch from '../../../../assets/images/watch-white.png';
import increase from '../../../../assets/consumerIcons/increase.svg';

const Wrapper = styled.div`
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`



const ShadowedDiv = styled.div`
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`

function FeatureCard({ wrapperClassName, dark }) {
  return (
    <div className="z-30">
      <ShadowedDiv className={`rounded-lg ${dark ? 'bg-consumerBlack' : 'bg-white'}`}>
        <div className="relative">
          <div className="">
            <Wrapper className="flex space-x-4 p-10 pr-0">
              <div className="w-full lg:w-auto">
                <div className={`text-2xl ${dark ? 'text-white' : 'text-consumerBlack'}`}>
                  {dark ? 'Bigest discounts' : 'Trending products'}
                </div>
                <div className="text-consumerGray mt-4.5">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto eius eveniet,
                  facilis ipsam laborum laudantium magni quasi quia rerum unde.
                </div>
                <button
                  type="button"
                  className={`text-white py-3.25 px-4.5 rounded mt-4.5 ${
                    dark ? 'bg-consumerPrimary' : 'bg-consumerBlack'
                  }`}
                >
                  Explore
                </button>
              </div>
              <div className="relative w-full lg:w-auto">
                <img src={watch} alt="watch" className="absolute -bottom-10 right-0" />
                <div className="z-10 pr-20">
                  {dark ? (
                    <div className="relative bg-consumerPrimary text-white text-4xl w-28 h-28 rounded-full">
                      <div className="absolute top-9 left-3">-10%</div>
                    </div>
                  ) : (
                    <div className="relative bg-consumerOrange text-white text-4xl w-28 h-28 rounded-full">
                      <img src={increase} alt="increase" className="absolute top-10 right-7" />
                    </div>
                  )}
                </div>
              </div>
            </Wrapper>
          </div>
        </div>
      </ShadowedDiv>
    </div>
  );
}

export default FeatureCard;
