import React, {useMemo} from 'react';
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  min-height: 240px;
  max-height: 240px;
  height: 240px;
  display: block;
    
    @media (max-width: 991px) {
        max-height: auto;
        height: 100%;
    }
`

function MiniProductCard({ wrapperClassName, loading = false, product = {} }) {

    const titleSkeletons = useMemo(() => {
        const count = Math.floor(Math.random() * 2 + 2);
        return [...Array(count)].map((_, i) => {
            return Math.floor(Math.random() * 76 + 24);
        })
    }, [loading])

  return (
    <Wrapper className={wrapperClassName} as={Link} to={`/product/amazon/${product?.asin}`}>
      <div className="flex flex-col items-center space-y-2 py-4.5 px-16 rounded bg-white h-full">
          {loading ? (
              <Skeleton height={108} width={108}/>
          ) : (
              <img className="w-24 h-24 object-contain" src={product?.image} alt={product?.title} />
          )}
        <div className="text-22 font-bold">
            {loading ? titleSkeletons?.map(item => {
                return (
                    <Skeleton height={22} width={item} style={{ borderRadius: 8, marginBottom: 6, marginRight: 8 }}/>
                )
            }) : product?.title?.length > 40 ? `${product?.title?.slice(0, 37)}...` : product?.title}
        </div>
        <div className="text-consumerPrimary font-bold">{loading ? (
            <Skeleton height={22} width={56} style={{ borderRadius: 8 }}/>
        ) : product?.price?.raw}</div>
      </div>
    </Wrapper>
  );
}

export default MiniProductCard;
