import React from 'react';
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import avatar from '../../../../assets/images/avatar.jpg';
import watch from '../../../../assets/images/watch-black.png';
import logo1 from '../../../../assets/images/logo1.png';
import amazon from '../../../../assets/consumerIcons/amazon.svg';
import {Jazzicon} from "@ukstv/jazzicon-react";
import {useActiveWeb3React} from "../../../../hooks";
import {shortenAddress} from "../../../../utils";

const ShadowedDiv = styled.div`
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`

const ModifiedJazzicon = styled(Jazzicon)`
	width: 40px;
	height: 40px;
	margin-right: 1rem;
`;

function ProductCard({ wrapperClassName, loading = false, order = {} }) {
	const { account } = useActiveWeb3React();

	return (
		<div className={wrapperClassName}>
			<ShadowedDiv className="border-1 border-solid border-consumerLighterGray bg-white rounded">
				<div className="py-4.5 px-6 border-b border-solid border-consumerLightGray">
					<div className="flex justify-between items-center">
						<div className="flex space-x-4 items-center">
							{loading ? (<Skeleton width={40} height={40} circle/>) : (<ModifiedJazzicon address={account || ""}/>)}

							<div className="text-lg font-bold">{loading ? <Skeleton width={68}/> : order?.user?.name || shortenAddress(order?.user?.address)}</div>
						</div>
						{loading ? (<Skeleton width={80} height={32}/>) : (<img className="mt-2.5" src={amazon} alt="amazon" />)}
					</div>
					<div className="flex justify-start items-center space-x-6 mt-4.5">
						{loading
							? (<Skeleton width={120} height={120}/>)
							: (<img src={order?.items?.[0]?.product?.mainImage?.link} alt={order?.items?.[0]?.product?.title} style={{ width: 120, height: 120 }} className={'object-contain'} />)
						}
						<div className={'flex-grow ml-6'}>
							<div className="text-lg font-bold">
								<div className="">
									{loading
										? <Skeleton width={80} height={32}/>
										: order?.items?.[0]?.product?.title?.length > 60
											? order?.items?.[0]?.product?.title?.slice(0, 57) + "..."
											: order?.items?.[0]?.product?.title
									}
								</div>
							</div>
							<div className="flex space-x-4">
								<div className="text-consumerFadePrimary">{loading ? (<Skeleton width={48} height={16}/>) : `${order?.currency?.symbol}${order?.finalPrice}`}</div>
								<div className="text-consumerBlack opacity-50">{loading ? (<Skeleton width={48} height={16}/>) : `${order?.currency?.symbol}${order?.totalPrice}`}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-between items-center py-2.5 px-6">
					<div className="text-consumerFadePrimary font-bold">
						{loading
							? (<Skeleton width={36} height={16}/>)
							: `${Math.floor((Number(order?.totalPrice) - Number(order?.finalPrice)) / Number(order?.totalPrice) * 100).toFixed(0)}% discount`}
					</div>
					<div className="flex justify-between items-center">
						<div className="mr-2.5 font-bold">{loading ? (<Skeleton width={32} height={16}/>) : "Paid with"}</div>
						{loading ? (<Skeleton width={36} height={36} circle/>) : <img className="pt-2.5" src={logo1} alt="logo" />}

					</div>
				</div>
			</ShadowedDiv>
		</div>
	);
}

export default ProductCard;
