import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import SplashScreen from "./components/SplashScreen";
import ShoppingRoutes from "./ShoppingRoutes";
import Home from './pages/Home';

const Crypto = lazy(() => import('./pages/Crypto'))

const Routes = () => {
	return (
		<Suspense fallback={<SplashScreen />}>
			<Switch>
				<Route path={'/'} exact component={Home}/>
				<Route path={'/foundational'} exact component={Crypto}/>
				<Route path={'/'} component={ShoppingRoutes}/>
				<Redirect to={"/"} />
			</Switch>
		</Suspense>
	);
};

export default Routes;
