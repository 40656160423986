import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { Button as BSButton } from "react-bootstrap";

import { headerRoutes } from "../../../../constants";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { addressShorten } from "../../../../utils/getAddressShorten";
import { useActiveWeb3React } from "../../../../hooks";
import CartIcon from '../../../../assets/images/navigation/cart.svg';
import SVG from "react-inlinesvg";
import AccountDropdown from "../../../AccountDropdown";
import {useSelector} from "react-redux";
import {useAuthentication} from "../../../../states/auth/hooks";

const ModifiedJazzicon = styled(Jazzicon)`
	width: 32px;
	height: 32px;
	margin-right: 1rem;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 991px) {
		display: none;
	}
`;

const Button = styled(BSButton)`
  height: 56px;
`

const List = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Item = styled.li`
	padding: 0 20px 0 10px;
	height: 56px;
	display: flex;
	align-items: center;
`;

const StyledLink = styled(NavLink)`
	text-decoration: none;
	outline: none;
	color: ${({ theme }) => theme.text3};
	font-weight: 500;
	font-size: 1rem;
	transition: 0.3s ease all;
	display: block;
	padding: 10px 0;

	&:hover,
	&.active {
		color: ${({ theme }) => theme.primary};
	}

	&:focus,
	&:active {
		outline: none;
	}
`;
const StyledAnchor = styled.a`
	text-decoration: none;
	outline: none;
	color: ${({ theme }) => theme.text3};
	font-weight: 500;
	font-size: 1rem;
	transition: 0.3s ease all;
	display: block;
	padding: 10px 0;

	&:hover,
	&.active {
		color: ${({ theme }) => theme.primary};
	}

	&:focus,
	&:active {
		outline: none;
	}
`;

const AccountView = styled.div`
	height: 56px;
	padding: 0 24px;
	background-color: ${({ theme }) => theme.primaryLight};
	color: ${({ theme }) => theme.text1};
	border-radius: 18px;
	display: flex;
	align-items: center;
`;

const AccountAddress = styled.span`
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	color: ${({ theme }) => theme.text1};
`;

const ButtonItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  min-width: 56px;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  margin-right: 20px;
  transition: 0.3s ease all;
  will-change: background-color, color;
  position: relative;
  
  &:first-child {
    margin-left: 10px;
  }
  
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }
`

const Badge = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 32px;
  font-weight: 300;
  font-size: .625rem;
  top: 13px;
  right: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ShoppingNavigation = (props) => {
    const { account } = useActiveWeb3React();
    const isAdminLoggedIn = useSelector(state => state.admin.isLoggedIn);
    const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn);
    const cart = useSelector(state => state.cart);

    return (
        <Container>
            <List>
                {Object.keys(headerRoutes).map((key) => {
                    const r = headerRoutes[key];
                    if(key !== 'cart') {
                        if("url" in r) {
                            return (
                                <Item>
                                    <StyledAnchor as={'a'} href={r.url}>
                                        {r.title}
                                    </StyledAnchor>
                                </Item>
                            )
                        }
                        return (
                            <Item>
                                <StyledLink exact to={r.path} activeClassName={"active"}>
                                    {r.title}
                                </StyledLink>
                            </Item>
                        );
                    }

                    return (
                        <ButtonItem to={r.path} activeClassName={'active'}>
                            <SVG src={CartIcon}/>
                            {Number(cart?.totalQty) > 0 && (
                                <Badge />
                            )}
                        </ButtonItem>
                    )

                })}
            </List>
            {isAdminLoggedIn ? (
                <AccountDropdown type={'admin'}/>
            ) : isLoggedIn ? (
                <>
                    {account && (
                        <Link to={"/account/limit"}>
                            <AccountView>
                                <ModifiedJazzicon address={account} />
                                <AccountAddress>{addressShorten(account)}</AccountAddress>
                            </AccountView>
                        </Link>
                    )}
                    <AccountDropdown type={'user'} noMargin={!account}/>
                </>
            ) : (
                <Link to={"/login"}>
                    <Button variant={"primary"}>Login</Button>
                </Link>
            )}
        </Container>
    );
};

export default ShoppingNavigation;
