import {Switch, Route, Redirect } from "react-router-dom";

import ShoppingLayout from "./components/Global/Layout/Shopping";
import AccountPage from "./components/AccountPage";
import AdminPage from "./components/AdminPage";
import {lazy} from "react";
import CartPage from "./components/CartPage";
import ShoppingHome from "./pages/ShoppingHome";

const OrderDetails = lazy(() => import('./pages/OrderDetails'));
const SearchPage = lazy(() => import('./pages/Search'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const Login = lazy(() => import('./pages/Auth/Login'));
const ForgetPassword = lazy(() => import('./pages/Auth/ForgetPassword'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const SignUp = lazy(() => import('./pages/Auth/SignUp'));

const ShoppingRoutes = props => {
    return (
        <ShoppingLayout>
            <Switch>
                <Route path={'/product/:providerId/:productId'} component={ProductPage}/>
                <Route path={'/search'} component={SearchPage}/>
                <Route path={'/order/:orderId'} component={OrderDetails}/>
                <Route path={'/cart'} component={CartPage}/>
                <Route path={'/account'} component={AccountPage}/>
                <Route path={'/admin'} component={AdminPage}/>
                <Route path={"/login"} exact component={Login} />
                <Route path={"/login/forget-password"} exact component={ForgetPassword} />
                <Route path={"/login/reset-password/:code"} exact component={ResetPassword} />
                <Route path={"/signup"} exact component={SignUp} />
                <Route path={'/shopping'} component={ShoppingHome} />
            </Switch>
        </ShoppingLayout>
    )
}

export default ShoppingRoutes;
