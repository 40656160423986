import styled from "styled-components";
import SVG from 'react-inlinesvg';

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  
  &:not(:last-child) {
    margin-right: 30px;
    
    @media (max-width: 1199px) {
      margin-right: 20px;
    }
  }
  
`

const Link = styled.a.attrs(() => ({
    target: "_blank"
}))`
  background-color: ${({ theme }) => theme.transparentBG};
  color: ${({ theme }) => theme.white};
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease all;
  
  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primary};
  }
`

const Socials = props => {
    return (
        <Wrapper>
            <Item>
                <Link href={'https://www.google.com/'}>
                    <SVG src={require('../../assets/images/socials/facebook.svg').default}/>
                </Link>
            </Item>
            <Item>
                <Link href={'https://www.google.com/'}>
                    <SVG src={require('../../assets/images/socials/phone.svg').default}/>
                </Link>
            </Item>
            <Item>
                <Link href={'https://www.google.com/'}>
                    <SVG src={require('../../assets/images/socials/mail.svg').default}/>
                </Link>
            </Item>
            <Item>
                <Link href={'https://www.google.com/'}>
                    <SVG src={require('../../assets/images/socials/twitter.svg').default}/>
                </Link>
            </Item>
        </Wrapper>
    )
}

export default Socials;