import React, { useState } from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';
import * as Scroll from 'react-scroll';

import magnifier from '../../../../assets/consumerIcons/magnifier.svg';
import logo from '../../../../assets/consumerIcons/logo.svg';
import {useActiveWeb3React} from "../../../../hooks";
import {useSelector} from "react-redux";
import {useAuthentication} from "../../../../states/auth/hooks";
import AccountDropdown from "../../../AccountDropdown";
import {addressShorten} from "../../../../utils/getAddressShorten";
import styled from "styled-components";
import {Jazzicon} from "@ukstv/jazzicon-react";

const HashLink = Scroll.Link;


const ModifiedJazzicon = styled(Jazzicon)`
	width: 32px;
	height: 32px;
	margin-right: 1rem;
`;

const AccountView = styled.div`
	height: 56px;
	padding: 0 24px;
	background-color: ${({ theme }) => theme.primaryLight};
	color: ${({ theme }) => theme.text1};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
`;

const AccountAddress = styled.span`
	font-size: 1rem;
	font-weight: 700;
	line-height: 1;
	color: ${({ theme }) => theme.text1};
`;

const ButtonItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  min-width: 56px;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  margin-right: 20px;
  transition: 0.3s ease all;
  will-change: background-color, color;
  position: relative;
  
  &:first-child {
    margin-left: 10px;
  }
  
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }
`


const Nav = styled.nav`
`

function Header() {
	const [openMenu, setOpenMenu] = useState(false);
	const [query, setQuery] = useState("");
	const history = useHistory();
	const { account } = useActiveWeb3React();
	const isAdminLoggedIn = useSelector(state => state.admin.isLoggedIn);
	const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn);
	const cart = useSelector(state => state.cart);

	const searchHandler = () => {
		if(query.length !== 0) {
			const urlParams = new URLSearchParams("");
			urlParams.set("query", query?.trim());
			history.push(`/search?${urlParams.toString()}`)
		}
	}

	const toggleHover = () => {
		setOpenMenu(!openMenu);
	};

	return (
		<header className="flex justify-between items-center py-4.5 px-4.5 lg:px-12 text-consumerDark">
			<div className="flex justify-between items-center w-full max-w-4xl z-20 mr-auto">
				<div className="flex items-center space-x-2 mr-20">
					<div className="font-consumerBlack text-2xl font-bold">Shopping</div>
					<img className="w-8 pt-2" src={logo} alt="logo" />
				</div>
				<div
					className="border-1 border-solid border-consumerLightGray
                        relative hidden lg:flex h-12 w-full placeholder-pl-none"
				>
					<img src={magnifier} alt="magnifier" className="p-3.25 pl-4.5" />
					<input
						type="text"
						className="py-2 rounded
            w-full h-full border-0 outline-none"
						placeholder="Search for anything on Amazon, eBay and Walmart or paste an Amazon ASIN"
						value={query}
						onChange={e => setQuery(e.target.value)}
						onKeyDown={e => e.key === "Enter" && searchHandler()}
					/>
				</div>
			</div>
			<button
				type="button"
				className="lg:hidden z-20 text-xs rounded border-white border px-2.5 py-1.5"
				onClick={toggleHover}
			>
				{openMenu ? 'CLOSE' : 'OPEN'}
			</button>

			<Nav
				className={`items-center z-10 transform lg:static text-center
					lg:translate-y-0 flex flex-col lg:flex-row items-stretch lg:items-center lg:justify-end absolute lg:ml-6 left-0 top-0 bg-white 
					transition
					w-full pt-28
					pb-10
					lg:py-0
					
					${openMenu ? 'translate-y-0' : '-translate-y-full'}`}
			>
				<ul className="flex justify-between lg:justify-end items-center w-full px-8 lg:px-0 flex-col py-2.5  lg:flex-row">
					<li className={'cursor-pointer'}>
						<HashLink smooth={true} to={'browse'} spy={true} activeClassName={"text-consumerPrimary"}>Browse</HashLink>
					</li>
					<li className="lg:border-r lg:border-solid lg:border-consumerLighterGray lg:pr-6 lg:pl-8 py-2.5 cursor-pointer">

						<HashLink smooth={true} to={'process'} spy={true} activeClassName={"text-consumerPrimary"}>How it works</HashLink>

					</li>
					<li className="py-2.5 lg:pl-8"><Link to={'/foundational'}>Crypto</Link></li>
					{isAdminLoggedIn ? (
						<li className="flex lg:hidden py-2.5"><Link to={'/admin'}>Admin panel</Link></li>
					) : isLoggedIn ? (
						<li className="flex lg:hidden py-2.5"><Link to={'/account'}>Account</Link></li>
					) : null}
				</ul>

				<div className="flex flex-col lg:flex-row items-center mt-4.5 lg:mt-0 lg:ml-8 px-6 lg:px-0">
					{isAdminLoggedIn ? (
						<div className={'hidden lg:flex items-center'}>
							<AccountDropdown type={'admin'} lessBorderRadius/>
						</div>
					) : isLoggedIn ? (
							<div className={'hidden lg:flex items-center'}>
								{account && (
									<Link to={"/login"}>
										<AccountView>
											<ModifiedJazzicon address={account} />
											<AccountAddress>{addressShorten(account)}</AccountAddress>
										</AccountView>
									</Link>
								)}
								<AccountDropdown type={'user'} lessBorderRadius noMargin={!account}/>
							</div>
					) : (
						<Link to={'/login'} className="bg-consumerPrimary text-white py-3.5 px-2.5 rounded w-full lg:w-auto">
							Login
						</Link>
					)}
				</div>
			</Nav>


		</header>
	);
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
