const routes = {
	shopping: {
		title: "Shopping",
		path: "/",
	},
	DAO: {
		title: "DAO",
		children: {
			governance: {
				title: "Governance",
				path: "/dao/governance",
			},
			farming: {
				title: "Farming",
				path: "/dao/farming",
			},
			amm: {
				title: "AMM",
				path: "/dao/amm",
			},
			buyCrypto: {
				title: "Buy Crypto",
				path: "/dao/buy-crypto",
			},
			bridge: {
				title: "Bridge",
				path: "/dao/bridge"
			}
		},
	},
	buyCrypto: {
		title: "Buy Crypto",
		path: "/dao/buy-crypto",
	},
	cart: {
		title: "Cart",
		path: "/cart",
	},
};

export const headerRoutes = {
	shopping: {
		title: "Shopping",
		path: "/",
	},
	DAO: {
		title: "DAO",
		url: `${process.env.REACT_APP_DAO_URL}`,
	},
	buyCrypto: {
		title: "Buy Crypto",
		url: `${process.env.REACT_APP_DAO_URL}/buy-crypto`,
	},
	cart: {
		title: "Cart",
		path: "/cart",
	},
};

export const accountRoutes = {
	accountInfo: {
		title: "Account Info",
		path: "/account/profile"
	},
	addresses: {
		title: "My Addresses",
		path: "/account/addresses",
	},
	txnHistory: {
		title: "Transactions history",
		path: "/account/transactions",
	},
	limits: {
		title: "Levels & Limits",
		path: "/account/limit"
	},
	logout: {
		title: "Logout",
		type: "action",
		action: "logout"
	}
}

export const adminRoutes = {
	orders: {
		title: "Orders",
		path: "/admin/orders"
	},
	vouchers: {
		title: "Vouchers",
		path: '/admin/vouchers'
	},
	logout: {
		title: "Logout",
		type: "action",
		action: "logout"
	}
}

export default routes;
