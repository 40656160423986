import styled from "styled-components";
import Logo from "../Logo";
import {NavLink} from "react-router-dom";
import { headerRoutes } from "../../../constants";
import Socials from "../../Socials";

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.footerBG};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  margin-top: 100px;

  @media (max-width: 991px) {
    padding-top: 30px;
    background-image: none;
    margin-top: 50px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
`

const CenterRow = styled(Row)`
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const List = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 50px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin: 24px 0 40px 0;
  }
`;

const Item = styled.li`
	padding: 0 20px 0 10px;
	height: 56px;
	display: flex;
	align-items: center;
  
    @media (max-width: 991px) {
      padding: 0 10px 0 20px;
    }
    @media (max-width: 991px) {
      padding: 0;
    }
`;

const StyledLink = styled(NavLink)`
	text-decoration: none;
	outline: none;
	color: ${({ theme }) => theme.white};
	font-weight: 500;
	font-size: 1rem;
	transition: 0.3s ease all;
	display: block;
	padding: 10px 0;

    @media (max-width: 991px) {
        padding: 5px 0;
    }
  
	&:hover,
	&.active {
		color: ${({ theme }) => theme.primary};
	}

	&:focus,
	&:active {
		outline: none;
	}
`;

const StyledAnchor = styled.a`
	text-decoration: none;
	outline: none;
	color: ${({ theme }) => theme.white};
	font-weight: 500;
	font-size: 1rem;
	transition: 0.3s ease all;
	display: block;
	padding: 10px 0;

    @media (max-width: 991px) {
        padding: 5px 0;
    }
  
	&:hover,
	&.active {
		color: ${({ theme }) => theme.primary};
	}

	&:focus,
	&:active {
		outline: none;
	}
`;

const Copyright = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 40px;
  
  @media (max-width: 1199px) {
    margin-right: 30px;
  }
  
  @media (max-width: 991px) {
    margin: 0;
    padding: 24px 0 32px;
  }
`


const Footer = props => {
    return (
        <Wrapper>
            <Container className="container">
                <Row>
                    <Logo hideTitle={false} />
                    <List>
                        {Object.keys(headerRoutes).map((key) => {
                            const r = headerRoutes[key];
                            if(key !== 'cart') {
                                if("url" in r) {
                                    return (
                                        <Item key={`footer-${key}`}>
                                            <StyledAnchor as={'a'} exact href={r.url} activeClassName={"active"}>
                                                {r.title}
                                            </StyledAnchor>
                                        </Item>
                                    )
                                }

                                return (
                                    <Item key={`footer-${key}`}>
                                        <StyledLink exact to={r.path} activeClassName={"active"}>
                                            {r.title}
                                        </StyledLink>
                                    </Item>
                                );
                            }
                        })}
                    </List>
                </Row>
                <CenterRow>
                    <Copyright>&copy; 2020&mdash;2021 All rights reserved.</Copyright>
                    <Socials />
                </CenterRow>
            </Container>
        </Wrapper>
    )
}

export default Footer;