import {useDispatch, useSelector} from "react-redux";
import Web3 from "web3";
import {connectWallet, disconnectWallet} from "./actions";
import api from "../../http/api";
import {useActiveWeb3React} from "../../hooks";
import {useMemo} from "react";
import {emptyCart} from "../cart/actions";

export const useAuthentication = () => {
    const { account } = useActiveWeb3React();
    const dispatch = useDispatch();
    const isSignedWallet = useSelector(state => state.auth.isSignedWallet);
    const address = useSelector(state => state.auth.address);

    const handleSignMessage = async (address, nonce) => {
        try {
            const web3 = new Web3(Web3.givenProvider);
            if(!web3) {
                dispatch(disconnectWallet());
                return Promise.reject("You need to sign the message to be able to log in.");
            }
            const signature = await web3?.eth.personal.sign(
                web3.utils.toHex(`Signing wallet address: ${nonce}`),
                address,
                null
            );

            return { address, signature };
        } catch (err) {
            throw new Error(
                'You need to sign the message to be able to log in.'
            );
        }
    }

    const handleAuthorization = async (accessToken) => {
        if(!account) {
            dispatch(disconnectWallet());
            return false;
        }

        const tempAddress = account;
        const publicAddress = account?.toLowerCase();
        if(publicAddress === address?.toLowerCase()) {
            dispatch(connectWallet(tempAddress));
            return true;
        }

        let connectedAddress;
        let nonce;
        try {
            const res = await api.getNonce({
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            const nonce = res.nonce;
            connectedAddress = publicAddress;

            const authBody = await handleSignMessage(connectedAddress, nonce)

            const authRes = await api.checkNonce(authBody, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if(authRes) {
                dispatch(connectWallet(tempAddress));
            }

            dispatch(emptyCart())
        } catch(e) {
            console.log('blocked', e);
            dispatch(disconnectWallet());
            dispatch(emptyCart())
            return false;
        }
    }

    return useMemo(() => {
        return [isSignedWallet, handleAuthorization];
    }, [isSignedWallet, account])
}
