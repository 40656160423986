import { createAction } from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import api from "../../http/api";
import { updateLogin as updateAdminLogin } from '../admin/actions';

export const disconnectWallet = createAction('auth/disconnect');
export const connectWallet = createAction('auth/connect');

export const updateLogin = createAction("auth/login", (accessToken, profile) => {
    return {
        payload: {
            accessToken,
            profile,
        }
    }
})
export const updateLogout = createAction('auth/logout');
export const updateProfile = createAction('auth/updateProfile');
export const setLimits = createAction('auth/limits');


export const login = (email, password) => {
    return async dispatch => {
        try {
            const res = await api.login({
                identifier: email,
                password,
            })

            if (res && res?.jwt) {
                toast.success("Logged in successfully");
                if(res?.user?.role?.name === 'Admin') {
                    dispatch(updateAdminLogin(res?.jwt, res?.user));
                } else {
                    dispatch(updateLogin(res?.jwt, res?.user));
                }

                return true;
            }

            return false;
        } catch (e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const signup = (username, email, password) => {
    return async dispatch => {
        try {
            const res = await api.signup({
                username,
                email,
                password
            })

            if (res && res?.jwt) {
                toast.success("Logged in successfully");
                dispatch(updateLogin(res?.jwt, res?.user));

                return true;
            }

            return false;
        } catch (e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const forgetPassword = (email) => {
    return async dispatch => {
        try {
            const res = await api.forgetPassword({
                email
            })

            if (res) {
                toast.success("The reset password email was sent, Please check your e-mail");
                return true;
            }

            return false;
        } catch (e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}


export const resetPassword = (password, passwordConfirmation, code) => {
    return async dispatch => {
        try {
            const res = await api.resetPassword({
                password, passwordConfirmation, code
            })

            if (res) {
                toast.success("Your password was changed successfully! Please login into your account.");
                return true;
            }

            return false;
        } catch (e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const logout = () => {
    return async dispatch => {
        try {
            toast.success("Logged out successfully");
            dispatch(updateLogout());
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const getProfile = (account) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.getUser({
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })
            const profile = await api.getProfile({
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            if(res) {
                dispatch(updateProfile({
                    ...res,
                    name: profile?.name || "",
                    lastname: profile?.lastname || ""
                }));
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            return false;
        }
    }
}

export const updateProfileAsync = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.updateUser(body, {
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            if(res) {
                const data = {
                    name: res.name,
                    lastname: res.lastname
                }
                dispatch(updateProfile(data));
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            return false;
        }
    }
}

export const getProfileLimits = () => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.getUserLimits({
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            if(res) {
                dispatch(setLimits(res));
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            return false;
        }
    }
}
