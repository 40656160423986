import {useCallback, useMemo, useState} from "react";

const useAsyncLoading = (fn, defaultLoadingState = false) => {
    const [loading, setLoading] = useState(defaultLoadingState);

    const asyncHandler = useCallback(async (...params) => {
        setLoading(true);
        try {
            const res = await fn(...params);
            setLoading(false);
            return res;
        } catch(e) {
            setLoading(false);
            return Promise.reject(e);
        }
    }, [fn])

    return useMemo(() => {
        return [loading, asyncHandler];
    }, [loading, asyncHandler])
}

export default useAsyncLoading;