import React from 'react';
import logo2 from '../../../../assets/images/logo2.png';
import logo3 from '../../../../assets/images/logo3.png';
import logo4 from '../../../../assets/images/logo4.png';
import logo5 from '../../../../assets/images/logo5.png';
import amazon from '../../../../assets/consumerIcons/amazon.svg';
import walmart from '../../../../assets/consumerIcons/walmart.svg';
import ebay from '../../../../assets/consumerIcons/ebay.svg';
import * as Scroll from "react-scroll";


const Link = Scroll.Link;

function Banner() {
  return (
    <div>
      <section className="lg:container">
        <div className="relative hidden lg:block">
          <img className="absolute top-4 -right-4 lg:right-20" src={logo2} alt="logo" />
          <img className="absolute top-56 right-4" src={logo3} alt="logo" />
          <img className="absolute top-14 -left-1 lg:left-20" src={logo4} alt="logo" />
          <img className="absolute top-80 left-4 pt-16 z-10" src={logo5} alt="logo" />
        </div>
        <div className="mx-auto pt-10">
          <div className="flex flex-col items-center">
            <h1 className="text-5xl font-bold text-center leading-16">
              The largest <br />
              crypto marketplace
            </h1>
            <div className="text-consumerGray text-center mt-4.5 px-7 leading-8">
              Buy From Amazon, Walmart And Ebay With Over <br /> 100 Cryptocurrencies by using
              shopping
            </div>
            <div className="flex items-center space-x-4 mx-auto mt-4.5">
              <Link smooth={true} to={'products'} className="cursor-pointer bg-consumerBlack border-1 border-solid border-consumerBlack text-white p-2.75 rounded">
                Explore
              </Link>
              <Link smooth={true} to={'process'} className="cursor-pointer border-1 border-solid border-consumerBlack p-2.75 text-consumerBlack hover:text-consumerBlack rounded">
                How it works
              </Link>
            </div>
            <div className="flex space-x-4 relative top-40 z-10">
              <img src={amazon} alt="amazon" />
              <img src={walmart} alt="walmart" />
              <img src={ebay} alt="ebay" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Banner;
