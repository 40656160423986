import styled from "styled-components";
import Logo from "../Logo";
import routes, {accountRoutes, adminRoutes} from "../../../constants/routes";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import MoonIcon from "../../Icons/Moon";
import SunIcon from "../../Icons/Sun";
import { useDarkModeManager } from "../../../states/preferences/hooks";
import useTheme from "../../../hooks/useTheme";
import SideDrawerDropdown from "./Dropdown";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	overflow: auto;
	width: 100%;
	background: ${({ theme }) => theme.bodyColor};
	transform: ${({ open }) => (open ? "scale(1)" : "scale(0.98)")};
	opacity: ${({ open }) => (open ? "1" : "0")};
	visibility: ${({ open }) => (open ? "visible" : "hidden")};
	pointer-events: ${({ open }) => (open ? "initial" : "none")};
	transition: 0.5s ease all;
	z-index: 100001;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 4rem;
`;

const Header = styled.div`
	padding: 24px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
`;

const CloseButton = styled.button`
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.text1};

	&:hover,
	&:focus,
	&:active {
		outline: none;
		text-decoration: none;
		box-shadow: none;
	}
`;

const LogoContainer = styled.div`
	padding: 0;
	display: flex;
	align-items: center;
`;

const LinkItem = styled(NavLink)`
	padding: 0 24px 0 30px;
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	justify-content: space-between;
	height: 48px;
	border-radius: 12px;
	margin-bottom: 8px;
	color: ${({ theme }) => theme.text2};
	font-size: 1rem;
	font-weight: 400;
	transition: 0.3s ease all;

	&:hover,
	&:focus,
	&:active,
	&.active {
		outline: none;
		text-decoration: none;
		color: ${({ theme }) => theme.text1};
		font-weight: 700;
	}
`;

const SwitchButton = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
	background-color: ${({ theme }) => theme.elementBG};
`;

const SwitchInner = styled.div`
	display: flex;
	flex-direction: column;
	width: 32px;
	height: 64px;
	left: 0;
	top: 0;
	transform: translateY(${({ darkMode }) => (darkMode ? "-32px" : "0")});
	transition: transform 0.3s ease;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
`;

const SideDrawer = ({ open, onDismiss, ...props }) => {
	const [darkMode, toggleDarkMode] = useDarkModeManager();
	const isAdminLoggedIn = useSelector(state => state.admin.isLoggedIn);
	const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn);
	const theme = useTheme();

	return (
		<>
			<Wrapper open={open}>
				<Content>
					<Header>
						<CloseButton onClick={onDismiss}>
							<SVG
								src={require("../../../assets/images/global/close.svg").default}
								width={16}
								height={16}
							/>
						</CloseButton>
						<LogoContainer>
							<Logo hideTitle />
						</LogoContainer>

						<SwitchButton>
							<SwitchInner darkMode={darkMode} onClick={toggleDarkMode}>
								<IconContainer>
									<MoonIcon size={15} fill={theme.text1} />
								</IconContainer>
								<IconContainer>
									<SunIcon size={22} fill={theme.text1} />
								</IconContainer>
							</SwitchInner>
						</SwitchButton>
					</Header>
					{isAdminLoggedIn ? (
						<SideDrawerDropdown title={"Admin"} items={adminRoutes} onDismiss={onDismiss} type={'admin'} />
					) : !isLoggedIn ? (
						<LinkItem exact activeClassName={"active"} onClick={onDismiss} to={"/login"}>
							Login
						</LinkItem>
					) : (
						<SideDrawerDropdown title={"Account"} items={accountRoutes} onDismiss={onDismiss} />
					)}

					{Object.keys(routes).map((key) => {
						const r = routes[key];
						if (r.hasOwnProperty("path")) {
							return (
								<LinkItem exact activeClassName={"active"} key={key} onClick={onDismiss} to={r.path}>
									{r.title}
								</LinkItem>
							);
						} else {
							return <SideDrawerDropdown title={r.title} items={r.children} onDismiss={onDismiss} />;
						}
					})}
				</Content>
			</Wrapper>
		</>
	);
};

export default SideDrawer;
