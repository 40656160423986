import React from 'react';
import thumbnail from '../../../../assets/consumerIcons/thumbnail.svg';
import ArrowSlider2 from '../UI/ArrowSlider2';
import {useHistory} from "react-router-dom";

export default function ShopCategories() {
  const history = useHistory();

  const categories = [
    { key: 1, title: 'Fashion', color: '#fac5e4' },
    { key: 2, title: 'Home & Kitchen', color: '#EFEDFD' },
    { key: 3, title: 'Toys & Games', color: '#F3FEF1' },
    { key: 4, title: 'Books', color: '#FDFFF0' },
    { key: 5, title: 'Electronics', color: '#FBF2EF' },
    { key: 6, title: 'Software', color: '#edfff9' },
    { key: 7, title: 'Fashion', color: '#fac5e4' },
    { key: 8, title: 'Home & Kitchen', color: '#EFEDFD' },
    { key: 9, title: 'Toys & Games', color: '#F3FEF1' },
    { key: 10, title: 'Books', color: '#FDFFF0' },
    { key: 11, title: 'Electronics', color: '#FBF2EF' },
    { key: 12, title: 'Software', color: '#edfff9' },
  ];


  const categorySearch = (category) => {
    const urlParams = new URLSearchParams("");
    urlParams.set("query", category?.trim());
    history.push(`/search?${urlParams.toString()}`)
  }


  return (
    <div className="mt-6">
      <div className="flex justify-between items-center container mt-20 mb-12">
        <h1 className="font-bold text-2xl">Shop categories</h1>
        <div className="flex items-center">
          <div className="mr-4.5 cursor-pointer">View all shop categories</div>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              /* eslint-disable-next-line max-len */
              d="M8.74995 16.625C8.54551 16.6254 8.34738 16.5542 8.18995 16.4238C8.10135 16.3503 8.02811 16.2601 7.97443 16.1583C7.92075 16.0565 7.88767 15.9451 7.87711 15.8305C7.86654 15.7159 7.87869 15.6003 7.91285 15.4904C7.94702 15.3805 8.00253 15.2784 8.0762 15.19L11.9962 10.5L8.2162 5.80126C8.14352 5.71176 8.08924 5.60877 8.05649 5.49823C8.02374 5.38768 8.01316 5.27175 8.02535 5.1571C8.03755 5.04245 8.07228 4.93134 8.12756 4.83016C8.18284 4.72898 8.25757 4.63972 8.34745 4.56751C8.43799 4.48785 8.54401 4.42777 8.65886 4.39103C8.77371 4.35429 8.89492 4.34168 9.01488 4.35401C9.13484 4.36633 9.25095 4.40332 9.35594 4.46265C9.46092 4.52197 9.55251 4.60236 9.62495 4.69876L13.8512 9.94876C13.9799 10.1053 14.0503 10.3017 14.0503 10.5044C14.0503 10.7071 13.9799 10.9034 13.8512 11.06L9.4762 16.31C9.38843 16.4159 9.27692 16.4996 9.15074 16.5543C9.02455 16.6091 8.88725 16.6333 8.74995 16.625Z"
              fill="#131E2E"
            />
          </svg>
        </div>
      </div>
      <div className="relative">
        <div className="absolute top-0 left-0 z-10 bg-gradient-to-r from-white w-24 h-24" />
        <ArrowSlider2>
          {categories?.map((c) => (
            <div className="flex flex-col items-center mr-10 cursor-pointer" onClick={categorySearch.bind(this, c?.title)} key={c?.key}>
              <div className="w-full flex justify-center">
                <div
                  className="w-24 h-24 relative rounded-full"
                  style={{ backgroundColor: c?.color }}
                >
                  <img className="absolute top-7 left-7" src={thumbnail} alt="thumbnail" />
                </div>
              </div>
              <div className="text-xl text-center mt-4.5">{c?.title}</div>
            </div>
          ))}
        </ArrowSlider2>
        <div className="absolute top-0 right-0 z-10 bg-gradient-to-l from-white w-24 h-24" />
      </div>
    </div>
  );
}
