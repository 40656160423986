import { createReducer } from "@reduxjs/toolkit";
import {connectWallet, disconnectWallet, setLimits, updateProfile, updateLogin, updateLogout} from "./actions";

const initialState = {
    accessToken: null,
    isLoggedIn: false,
    isSignedWallet: false,
    profile: null,
    limits: undefined,
    balance: null,
    account: null,
};

export default createReducer(initialState, builder =>
    builder
        .addCase(disconnectWallet, (state, action) => {
            state.isSignedWallet = false;
            state.account = null;
        })
        .addCase(connectWallet, (state, action) => {
            state.isSignedWallet = true;
            state.account = action.payload;
        })
        .addCase(updateProfile, (state, action) => {
            state.profile = {
                ...state.profile,
                ...action.payload
            };
        })
        .addCase(setLimits, (state, action) => {
            state.limits = action.payload.limits;
            state.balance = action.payload.balance;
        })
        .addCase(updateLogin, (state , action) => {
            state.isLoggedIn = true;
            state.accessToken = action.payload.accessToken;
            state.profile = action.payload.admin;
        })
        .addCase(updateLogout, (state, action) => {
            state.isLoggedIn = false;
            state.accessToken = null;
            state.profile = null;
            state.isSignedWallet = false;
            state.account = null
        })
)
