import { createAction } from "@reduxjs/toolkit";
import api from "../../http/api";
import {toast} from "react-hot-toast";

export const setCart = createAction('cart/set');
export const emptyCart = createAction('cart/empty');
export const setUpdater = createAction('cart/updater');

export const getCart = () => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.getCart({
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                dispatch(setCart(res?.cart));
                return res?.cart;
            }

            return false;
        } catch(e) {
            console.log(e);
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const addProduct = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.addProduct(body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                dispatch(setUpdater());
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const updateProduct = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.updateProduct(body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                dispatch(setUpdater());
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }

}

export const deleteProduct = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.deleteProduct(id, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                dispatch(setUpdater());
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const addShippingAddress = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.updateCartAddress(body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            return !!res;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const addShippingMethod = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.updateCartShipping(body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            return !!res;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const applyVoucherCode = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.applyVoucher(body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                toast.success("The voucher code applied!");
                dispatch(setUpdater());
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const checkout = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.checkout(body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}
