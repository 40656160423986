import React, {useEffect, useState} from 'react';
import ProductCard from './ProductCard';
import SwipeSlider from '../UI/SwipeSlider';
import {getUserOrders} from "../../../../states/orders/actions";
import {useDispatch, useSelector} from "react-redux";
import {useActiveWeb3React} from "../../../../hooks";

export default function ProductsSlider({ title }) {
	const dispatch = useDispatch();
	const orders = useSelector(state => state.orders.list);
	const [noOrder, setNoOrder] = useState(false);
	const [loading, setLoading] = useState(false);
	const { account } = useActiveWeb3React();

	useEffect(() => {
		getOrdersHandler();
	}, [account])

	const getOrdersHandler = async () => {
		setLoading(true);
		setNoOrder(false);
		const resCount = await dispatch(getUserOrders({
			page: 1,
			limit: 30
		}, true))

		setLoading(false);
		if(!resCount || resCount === 0) {
			setNoOrder(true);
		}

	}

	return (
		<div className="">
			{title && <h1 className="font-bold text-4xl mb-16 text-center">{title}</h1>}
			<div className="">
				{noOrder ? (
					<div className={'flex flex-col items-center z-30 pt-10 pb-36'}>
						<h1 className="text-4xl font-bold text-center leading-16 text-consumerGray">
							{!account ? "Login into your account" : "No order was founded"}
						</h1>

						<div className="text-consumerLightGray text-center mt-3.25 px-7 leading-8">
							Please Submit an order to see the details
						</div>
					</div>
				) : loading ? (
					<SwipeSlider>
						{[...Array(20)].map((_, i) => {
							return (
								<ProductCard
									key={`loading-${i}`}
									loading
									wrapperClassName="mx-3.25"
								/>
							)
						})}
					</SwipeSlider>
				) : (
					<SwipeSlider infinite={false}>
						{orders?.map(order => {
							return (
								<ProductCard
									key={order?._id}
									order={order}
									wrapperClassName="mx-3.25"
								/>
							)
						})}
					</SwipeSlider>
				)}

			</div>
		</div>
	);
}
