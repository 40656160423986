import { createReducer } from "@reduxjs/toolkit";
import {emptyCart, setCart, setUpdater} from "./actions";

const initialState = {
    items: [],
    shipping: {
        method: null,
        address: null,
    },
    user: null,
    totalQty: 0,
    totalPrice: 0,
    totalPriceAfterVoucher: 0,
    updater: 0,
}

export default createReducer(initialState, builder =>
    builder
        .addCase(emptyCart, (state, action) => {
            state = {
                ...initialState,
                updater: state.updater === 0 ? -1 : 0
            };

            return state;
        })
        .addCase(setCart, (state, action) => {
            state = {
                ...state,
                ...action.payload,
            }

            return state;
        })
        .addCase(setUpdater, (state, action) => {
            state.updater++;
        })
);
