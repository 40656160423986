/*eslint-disable*/
import React, {useEffect, useState} from 'react';

import Layout from '../../components/Global/Layout/ConsumerLayout/MainLayout';
import FeatureCard from '../../components/landings/consumer/common/FeatureCard';
import magnifier from '../../assets/consumerIcons/magnifier.svg';
import curve from '../../assets/images/curve.svg';
import ProductsMinimalSlider from '../../components/landings/consumer/common/ProductsMinimalSlider';
import ProductsSlider from '../../components/landings/consumer/common/ProductsSlider';
import Process from '../../components/landings/consumer/common/Process';
import ShopCategories from '../../components/landings/consumer/common/ShopCategories';
import Banner from '../../components/landings/consumer/common/Banner';
import './style.css';
import {useHistory} from "react-router-dom";
import * as Scroll from "react-scroll";
import {useDispatch} from "react-redux";
import {getHome} from "../../states/shop/actions";
import api from "../../http/api";

const Element = Scroll.Element;

export default function Home() {
	const [query, setQuery] = useState("");
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [sections, setSections] = useState({});
	const [featured, setFeatured] = useState([]);
	const [newest, setNewest] = useState([]);
	const [computerComponents, setComputerComponents] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		getSections();
	}, [dispatch])

	const getSections = async () => {
		setLoading(true);
		const promises = [];
		promises.push(getFeaturedProducts())
		promises.push(getNewestProducts())
		promises.push(getComputerComponentsProducts())

		await Promise.all(promises);
		setLoading(false);
	}

	const getFeaturedProducts = async () => {
		const res = await api.home({
			type: 'category',
			url: "https://www.amazon.com/s?bbn=16225009011&rh=n%3A%2116225009011%2Cn%3A502394%2Cn%3A281052",
			sort_by: "featured"
		})

		setFeatured(res?.category_results);
		return res;
	}

	const getNewestProducts = async () => {
		const res = await api.home({
			type: 'category',
			url: "https://www.amazon.com/s?i=specialty-aps&bbn=16225009011&rh=n%3A%2116225009011%2Cn%3A172541",
			sort_by: 'most_recent'
		})

		setNewest(res?.category_results);
		return res;
	}

	const getComputerComponentsProducts = async () => {
		const res = await api.home({
			type: 'category',
			url: "https://www.amazon.com/s?i=specialty-aps&bbn=16225007011&rh=n%3A16225007011%2Cn%3A193870011",
		})

		setComputerComponents(res?.category_results);
		return res;
	}

	const searchHandler = () => {
		if(query.length !== 0) {
			const urlParams = new URLSearchParams("");
			urlParams.set("query", query?.trim());
			history.push(`/search?${urlParams.toString()}`)
		}
	}

	return (
		<Layout wrapperClassName="">
			<div className="mt-8 w-full text-consumerDark">
				<Banner />
				<div className="relative">
					<img className="hidden xl:block w-full absolute top-4" src={curve} alt="bg-curve" />
					<section className="overflow-hidden mx-auto z-10 pt-48 mt-16 products-slider relative">
						<ProductsSlider />
					</section>
				</div>
				<div className="container mt-10 lg:mt-48">
					<section className="mx-auto w-full max-w-4xl lg:max-w-7xl">
						<div className="flex flex-col items-center lg:flex-row lg:justify-center justify-between lg:space-x-4 space-y-4 lg:space-y-0 w-full">
							<FeatureCard dark />
							<FeatureCard />
						</div>
					</section>
				</div>
				<Element name={'browse'} />
				<section className="container mx-auto mt-32 lg:mt-48">
					<h1 className="relative text-5xl font-bold text-center leading-16">
						Buy your first product <br />
						with one of your crypto’s
					</h1>
					<div className="flex justify-center mt-8 mx-auto">
						<div
							className="border-1 border-solid border-consumerLightGray rounded-bl rounded-tl rounded-tr-none  rounded-br-none
                        relative flex h-12 w-full placeholder-pl-none max-w-2xl"
						>
							<img src={magnifier} alt="magnifier" className="p-3.25 pl-4.5" />
							<input
								type="text"
								className="py-2.75 rounded w-full h-full border-0 outline-none"
								placeholder="Search for anything on Amazon, eBay and Walmart or paste an Amazon ASIN"
								value={query}
								onChange={e => setQuery(e.target.value)}
								onKeyDown={e => e.key === "Enter" && searchHandler()}
							/>
						</div>
						<button type={"button"} onClick={searchHandler} className="bg-consumerBlack text-white rounded-r px-4.5">search</button>
					</div>
				</section>
				<section className="mt-6 overflow-hidden">
					<ShopCategories />
				</section>

				<Element name={'process'} />
				<section className="container flex justify-center mt-20 lg:mt-40 overflow-hidden">
					<Process />
				</section>

				<section className="mx-auto mt-16 bg-consumerLightBlue space-x-4 py-10 overflow-hidden">
					<Element name={'products'} />
					<ProductsMinimalSlider title="Featured products" loading={loading} data={featured} />
					<ProductsMinimalSlider title="Newest Products" loading={loading} data={newest} />
					<ProductsMinimalSlider title="Computer components" loading={loading} data={computerComponents} />
				</section>
			</div>
		</Layout>
	);
}
