import {createAction} from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const setUserOrders = createAction('orders/set');
export const updateUserOrders = createAction('order/update');

export const getUserOrders = (params, noLog = false) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt && !noLog) {
            toast.error("You should sign your wallet to continue");
            return false;
        }


        try {
            const newParams = {
                _start: (params.page - 1) * params?.limit,
                _limit: params?.limit,
            }
            const res = await api.getUserAllOrders({
                headers: {
                    "Authorization": `Bearer ${jwt}`
                },
                params: newParams
            })

            if(res) {
                if(params.page === 1) {
                    dispatch(setUserOrders(res));
                } else {
                    dispatch(updateUserOrders(res));
                }
                return res?.results;
            }

            return false;
        } catch(e) {
            if(!noLog) {
                if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }
            }
            return false;
        }
    }
}

export const getUserOrder = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.getUserOrder(id, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}
