import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	addPopup,
	PopupContent,
	removePopup,
	ApplicationModal,
	setOpenModal
} from "./actions";
import {AppDispatch, AppState} from "../index";
import { useActiveWeb3React } from "../../hooks";


export function useBlockNumber(): number | undefined {
	const { chainId } = useActiveWeb3React();

	return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1]);
}

export function useWalletModalOpen(): boolean {
	return useSelector((state: AppState) => state.application.walletModalOpen);
}


export function useModalOpen(modal: ApplicationModal): boolean {
	const openModal = useSelector((state: AppState) => state.application.openModal);
	return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
	const open = useModalOpen(modal);
	const dispatch = useDispatch<AppDispatch>();
	return useCallback(() => {
		dispatch(setOpenModal(open ? null : modal));
	}, [dispatch, modal, open]);
}

export function useOpenModal(modal: ApplicationModal): () => void {
	const dispatch = useDispatch<AppDispatch>();
	return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal]);
}

export function useCloseModals(): () => void {
	const dispatch = useDispatch<AppDispatch>();
	return useCallback(() => dispatch(setOpenModal(null)), [dispatch]);
}

export function useWalletModalToggle(): () => void {
	return useToggleModal(ApplicationModal.WALLET);
}

export function useToggleSettingsMenu(): () => void {
	return useToggleModal(ApplicationModal.SETTINGS);
}

export function useShowClaimPopup(): boolean {
	return useModalOpen(ApplicationModal.CLAIM_POPUP);
}

export function useToggleShowClaimPopup(): () => void {
	return useToggleModal(ApplicationModal.CLAIM_POPUP);
}

export function useToggleSelfClaimModal(): () => void {
	return useToggleModal(ApplicationModal.SELF_CLAIM);
}


export function useSettingsMenuOpen(): boolean {
	return useSelector((state: AppState) => state.application.settingsMenuOpen);
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
	const dispatch = useDispatch();

	return useCallback(
		(content: PopupContent, key?: string) => {
			dispatch(addPopup({ content, key }));
		},
		[dispatch]
	);
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
	const dispatch = useDispatch();
	return useCallback(
		(key: string) => {
			dispatch(removePopup({ key }));
		},
		[dispatch]
	);
}

// get the list of active popups
export function useActivePopups(): AppState["application"]["popupList"] {
	const list = useSelector((state: AppState) => state.application.popupList);
	return useMemo(() => list.filter((item) => item.show), [list]);
}
