import { Button as BS } from "react-bootstrap";
import styled from "styled-components";

import Loading from '../Loading';

const Button = styled(BS)`
  height: 56px;
  min-width: 125px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingButton = ({
    loading,
    children,
    parentComponent = Button,
    ...props
}) => {
    const Comp = parentComponent || Button;

    return (
        <Comp {...props} disabled={loading}>
            {loading ? (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <Loading width={18} height={18} color={'currentColor'} active id={`voucher-loading`} />
                </div>
            ) : children}
        </Comp>
    )
}

export default LoadingButton;