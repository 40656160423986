import { createAction } from '@reduxjs/toolkit';
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const setVouchers = createAction('vouchers/set');
export const updateVouchers = createAction("vouchers/update");

export const deleteVoucherFromList = createAction('vouchers/deleteItem');


export const getAllVouchers = (params) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const newParams = {
                _start: (params.page - 1) * params?.limit,
                _limit: params?.limit,
            }
            const res = await api.getAllVouchers({
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
                params: newParams
            })

            if(res) {
                if(params.page === 1) {
                    dispatch(setVouchers(res));
                } else {
                    dispatch(updateVouchers(res));
                }
                return res?.results;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const getVoucher = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.getVoucher(id, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const deleteVoucher = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.deleteVoucher(id, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                dispatch(deleteVoucherFromList(id));
                toast.success("Voucher code deleted successfully!")
                return true;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const createVoucher = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.createVoucher(body, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                toast.success("Voucher code created successfully!")
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}

export const updateVoucher = (id, body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.updateVoucher(id, body, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                toast.success("Voucher code updated successfully!")
                return res;
            }

            return false;
        } catch(e) {
            if(typeof e?.message === 'string') {
                toast.error(e?.message)
            } else {
                e?.message?.[0]?.messages.map(err => {
                    if(err?.message) {
                        toast.error(err?.message || "An error occurred, please try again.");
                    }
                })
            }

            return false;
        }
    }
}
