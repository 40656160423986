import styled from "styled-components";
import {useState, useCallback, useRef} from "react";
import SVG from "react-inlinesvg";
import { darken } from "polished";
import {amazonUrls} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {setAmazonUrl} from "../../states/shop/actions";
import useToggle from "../../hooks/useToggle";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Wrapper = styled.div`
	position: relative;
`;

const Button = styled.button`
	height: ${({ size }) => (size === "sm" ? "32px" : "56px")};
	padding-left: ${({ size }) => (size === "sm" ? "16px" : "20px")};
	padding-right: 14px;
	border-radius: ${({ size }) => (size === "sm" ? "12px" : "18px")};
	background-color: ${({ theme }) => theme.primaryLight};
	transition: 0.4s ease all;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${({ theme }) => theme.text1};
	border: none;
    z-index: 1;
    position: relative;
    text-overflow: ellipsis;
  flex-wrap: nowrap;
  white-space: nowrap;
    min-width: 176px;

	&:hover {
		background-color: ${({ theme }) => theme.primary};
		color: ${({ theme }) => theme.text1};
	}

	&:focus,
	&:active {
		outline: none;
		background-color: ${({ theme }) => darken(0.1, theme.primary)};
		box-shadow: 0 0 0 5px rgba(135, 220, 225, 0.15);
		color: ${({ theme }) => theme.text1};
	}

	@media (max-width: 991px) {
		font-size: 0.875rem;
		padding-left: 15px;
		padding-right: 21px;
        width: 100%;
	}
`;

const ButtonText = styled.span`
	color: currentColor;
	font-weight: 500;
	font-size: 0.875rem;
	padding-right: 1rem;

	@media (max-width: 991px) {
		padding-right: 0.5rem;
	}
`;

const DropdownList = styled.div`
    background-color: ${({ theme }) => theme.popUpBG};
    border: 1px solid ${({ theme }) => theme.text4};
    box-shadow: 0 4px 52px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	left: 0;
	top: ${({ size, reverse }) => (reverse ? 'auto' : size === "sm" ? `42px` : `66px`)};
    bottom: ${({ size, reverse }) => (!reverse ? 'auto' : size === 'sm' ? '42px' : "66px")};
	min-width: 100%;
	padding: ${({ size }) => (size === "sm" ? `6px` : `8px`)};
	transition: 0.5s ease all;
	transform-style: preserve-3d;
	transform-origin: top center;
	transform: ${({ isOpen, reverse }) => (isOpen ? `translateY(0)` : `translateY(${reverse ? '-30px' : "30px"})`)};
	opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
	visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
    z-index: 999;
`;

const DropdownItem = styled.button`
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text1};
    white-space: nowrap;
    padding: 10px;
    border-radius: 16px;
    width: 100%;
    transition: 0.3s ease all;
    
    
    &:not(:last-child) {
      margin-bottom: ${({ size }) => (size === "sm" ? `2px` : `4px`)};
    }
    @media (max-width: 991px) {
      font-size: 0.875rem;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
      box-shadow: none;
    }
    
    &:hover {
      background-color: ${({ theme }) => theme.elementBG};
    }

`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border: 2px solid ${({ theme }) => theme.text2};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 300px;
  margin-right: 12px;
`

const Icon = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`

const AmazonSelector = (props) => {
    const active = useSelector(state => state.shop.amazonUrl);
    const dispatch = useDispatch();
    const [open, toggle] = useToggle(false);
    const node = useRef(null);

    useOnClickOutside(node, open ? toggle : undefined);

    const changeActiveHandler = useCallback(
        (item) => {
            dispatch(setAmazonUrl(amazonUrls[item]));
            toggle();
        },
        []
    );

    return (
        <Wrapper ref={node} >
            <Button variant={props.variant} onClick={toggle} isOpen={open} size={"md"}>
                <div className="d-flex align-items-center">
                    {active && (
                        <IconContainer>
                            <Icon
                                src={active?.flag}
                            />
                        </IconContainer>
                    )}
                    <ButtonText>{active ? active?.title : props.placeholder || "Select"}</ButtonText>
                </div>
                <SVG src={require("../../assets/images/dropdown.svg").default} />
            </Button>
            <DropdownList isOpen={open} size={"md"} reverse={props.reverse}>
                {Object.keys(amazonUrls).map((key) => {
                    const item = amazonUrls[key];

                    return (
                        <DropdownItem onClick={changeActiveHandler.bind(this, key)} key={key}>
                            <div className="d-flex align-items-center">
                                <IconContainer>
                                    <Icon
                                        src={item?.flag}
                                    />
                                </IconContainer>
                                <span>{item.title}</span>
                            </div>
                        </DropdownItem>
                    );
                })}
            </DropdownList>
        </Wrapper>
    );
};

export default AmazonSelector;
