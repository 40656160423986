import instance from "./instance";

class Api {
    constructor() {
        this.req = instance;
    }

    // Admin routes
    adminLogin(body) {
        return this.req.post('auth/local', body);
    }


    // Vouchers
    getAllVouchers(config = {}) {
        return this.req.get('vouchers', config)
    }

    getVoucher(id, config = {}) {
        return this.req.get(`vouchers/${id}`, config);
    }

    createVoucher(body, config = {}) {
        return this.req.post(`vouchers`, body, config);
    }

    updateVoucher(id, body, config = {}) {
        return this.req.put(`vouchers/${id}`, body, config);
    }

    deleteVoucher(id, config = {}) {
        return this.req.delete(`vouchers/${id}`, config);
    }

    // User authentication
    getUser(config = {}) {
        return this.req.get(`users/me`, config);
    }

    getProfile(config = {}) {
        return this.req.get(`profiles/me`, config);
    }

    updateUser(body, config = {}) {
        return this.req.put(`profiles/update`, body, config);
    }

    getUserLimits(config = {}) {
        return this.req.get(`authentication/limits`, config);
    }

    login(body) {
        return this.req.post('auth/local', body);
    }

    signup(body) {
        return this.req.post('auth/local/register', body);
    }

    forgetPassword(body) {
        return this.req.post('auth/forgot-password', body);
    }

    resetPassword(body) {
        return this.req.post('auth/reset-password', body);
    }

    getNonce(config = {}) {
        return this.req.post('authentication/generate-nonce', {}, config)
    }

    checkNonce(body, config = {}) {
        return this.req.post('authentication/check-nonce', body, config)
    }

    // User Address
    getAllAddresses(config = {}) {
        return this.req.get('addresses', config)
    }

    getAddress(id, config = {}) {
        return this.req.get(`addresses/${id}`, config);
    }

    createAddress(body, config = {}) {
        return this.req.post(`addresses`, body, config);
    }

    updateAddress(id, body, config = {}) {
        return this.req.put(`addresses/${id}`, body, config);
    }

    deleteAddress(id, config = {}) {
        return this.req.delete(`addresses/${id}`, config);
    }

    // Shop
    search(params) {
        return this.req.get('shop/amazon', {
            params: {
                ...params,
                type: 'search'
            }
        })
    }

    product(params) {
        return this.req.get('shop/amazon', {
            params: {
                ...params,
                type: 'product'
            }
        })
    }

    home(params) {
        return this.req.get('shop/amazon', {
            params: {
                ...params
            }
        })
    }

    // Cart
    getCart(config = {}) {
        return this.req.get('carts', config);
    }

    addProduct(body, config = {}) {
        return this.req.post('carts', body, config)
    }

    updateProduct(body, config = {}) {
        return this.req.put('carts', body, config);
    }

    deleteProduct(id, config = {}) {
        return this.req.delete(`carts/${id}`, config);
    }

    updateCartAddress(body, config = {}) {
        return this.req.put('carts/address', body, config);
    }

    updateCartShipping(body, config = {}) {
        return this.req.put('carts/shipping', body, config);
    }

    checkout(body, config = {}) {
        return this.req.post('carts/checkout', body, config);
    }

    applyVoucher(body, config = {}) {
        return this.req.post('carts/voucher', body, config);
    }


    // Admin orders
    getAllOrders(config = {}) {
        return this.req.get('orders', config);
    }

    getOrder(id, config = {}) {
        return this.req.get(`orders/admin/${id}`, config);
    }

    deleteOrder(id, config = {}) {
        return this.req.delete(`orders/${id}`, config);
    }

    updateOrderStatus(id, body, config = {}) {
        return this.req.put(`orders/${id}`, body, config)
    }

    addOrderNote(id, body, config = {}) {
        return this.req.post(`orders/${id}/note`, body, config)
    }

    // User orders
    getUserAllOrders(config = {}) {
        return this.req.get(`orders/me`, config);
    }

    getUserOrder(id, config = {}) {
        return this.req.get(`orders/${id}`, config);
    }

    // Ebay shopping
    searchEbay(params) {
        return this.req.get('shop/ebay/search', {
            params,
        })
    }

    getItemEbay(id, params) {
        return this.req.get(`shop/ebay/item/${id}`, {
            params
        })
    }

    // Walmart shopping

    searchWalmart(params) {
        return this.req.get('shop/walmart/search', {
            params,
        })
    }

    getItemWalmart(id, params) {
        return this.req.get(`shop/walmart/item/${id}`, {
            params
        })
    }
}

const api = new Api();

export default api;
