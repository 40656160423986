import styled from "styled-components";
import Img from "../Img";
import {Link} from "react-router-dom";
import DefaultSkeleton from "react-loading-skeleton";
import {useMemo} from "react";


const Skeleton = styled(DefaultSkeleton)`
  background-color: rgba(0, 0, 0, 0.05);
`


const Wrapper = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  flex-direction: column;
  align-items: stretch;
  border-radius: 40px;
  padding: 16px 16px 28px;
  background-color: ${({ theme }) => theme.elementBG};
    border: 1px solid ${({ theme }) => theme.text4};
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.05);
  height: 100%;
  overflow: hidden;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }
`


const ProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background-color: ${({theme, loading}) => loading ? 'transparent' : theme.white};
  border: 1px solid ${({ theme }) => theme.text4};
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  margin-bottom: 16px;
`

const ProductImage = styled(Img)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  max-height: 85%;
  object-fit: cover;
  
`

const Title = styled.span`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
  line-height: 22px;
  margin-bottom: ${({ loading }) => loading ? '16px' : '24px'};


  @media (max-width: 991px) {
    font-size: 1rem;
    line-height: 20px;
  }
`

const FinalPrice = styled.div`
  padding: 6px 32px 6px 16px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0 18px 18px 0;
  color: ${({ theme }) => theme.white};
  margin-left: -16px;
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    font-size: 1rem;
  }
`

const BasePrice = styled.span`
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.text3};
  text-decoration: line-through;

  @media (max-width: 991px) {
    font-size: 0.875rem;
  }
`

const AmazonLink = styled.a.attrs(() => ({
    target: "_blank"
}))`
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  transform: translateX(0);
  position: relative;
  transition: 0.35s ease all;
  cursor: pointer;
  
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  
  &::after {
    content: "🡕";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-4px);
    color: ${({ theme }) => theme.text3};
    opacity: 0;
    transition: 0.35s ease all;
  }
  
  &:hover {
    color: ${({ theme }) => theme.primary};
    transform: translateX(-16px);
    
    &::after {
      opacity: 1;
      transform: translateY(-50%) translateX(16px);
      color: ${({ theme }) => theme.primary};
    }
  }
  
`

const ProductCard = ({ item, loading = false, type = 0 }) => {
    const titleSkeletons = useMemo(() => {
        const count = Math.floor(Math.random() * 4 + 3);
        return [...Array(count)].map((_, i) => {
            return Math.floor(Math.random() * 76 + 24);
        })
    }, [loading])


    return type === 0 ? (
        <Wrapper to={!loading && `/product/amazon/${item?.asin}`}>
            {!loading ? (
                <ProductImageContainer loading={loading}>
                    <ProductImage
                        src={item?.image}
                        alt={item?.title}
                    />
                </ProductImageContainer>
            ) : (
                <Skeleton height={280} width={'100%'} style={{ borderRadius: 32, marginBottom: 16 }}/>
            )}
            <Title loading={loading}>{loading ? titleSkeletons?.map(item => {
                return (
                    <Skeleton height={22} width={item} style={{ borderRadius: 8, marginBottom: 6, marginRight: 8 }}/>
                )
            }) : item?.title?.length > 70 ? `${item?.title?.slice(0, 67)}...` : item?.title}</Title>
            <div className="d-flex flex-column align-items-start mt-auto">
                <FinalPrice>{loading ? (
                    <Skeleton height={22} width={56} style={{ borderRadius: 8 }}/>
                ) : item?.price?.raw}</FinalPrice>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <BasePrice />
                {loading ? (
                    <Skeleton height={22} width={80} style={{ borderRadius: 8 }}/>
                ) : (
                    <AmazonLink href={item?.link}>Amazon</AmazonLink>
                )}
            </div>
        </Wrapper>
    ) : type === 1 ? (
        <Wrapper to={!loading && `/product/ebay/${item?.itemId}`}>
            {!loading ? (
                <ProductImageContainer loading={loading}>
                    <ProductImage
                        src={item?.image?.imageUrl}
                        alt={item?.title}
                    />
                </ProductImageContainer>
            ) : (
                <Skeleton height={280} width={'100%'} style={{ borderRadius: 32, marginBottom: 16 }}/>
            )}
            <Title loading={loading}>{loading ? titleSkeletons?.map(item => {
                return (
                    <Skeleton height={22} width={item} style={{ borderRadius: 8, marginBottom: 6, marginRight: 8 }}/>
                )
            }) : item?.title?.length > 70 ? `${item?.title?.slice(0, 67)}...` : item?.title}</Title>
            <div className="d-flex flex-column align-items-start mt-auto">
                <FinalPrice>{loading ? (
                    <Skeleton height={22} width={56} style={{ borderRadius: 8 }}/>
                ) : item?.price?.value}</FinalPrice>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <BasePrice />
                {loading ? (
                    <Skeleton height={22} width={80} style={{ borderRadius: 8 }}/>
                ) : (
                    <AmazonLink href={item?.itemWebUrl}>Ebay</AmazonLink>
                )}
            </div>
        </Wrapper>
    ) : (
        <Wrapper to={!loading && `/product/${item?.asin}`}>
            {!loading ? (
                <ProductImageContainer loading={loading}>
                    <ProductImage
                        src={item?.image}
                        alt={item?.title}
                    />
                </ProductImageContainer>
            ) : (
                <Skeleton height={280} width={'100%'} style={{ borderRadius: 32, marginBottom: 16 }}/>
            )}
            <Title loading={loading}>{loading ? titleSkeletons?.map(item => {
                return (
                    <Skeleton height={22} width={item} style={{ borderRadius: 8, marginBottom: 6, marginRight: 8 }}/>
                )
            }) : item?.title?.length > 70 ? `${item?.title?.slice(0, 67)}...` : item?.title}</Title>
            <div className="d-flex flex-column align-items-start mt-auto">
                <FinalPrice>{loading ? (
                    <Skeleton height={22} width={56} style={{ borderRadius: 8 }}/>
                ) : item?.price?.raw}</FinalPrice>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <BasePrice />
                {loading ? (
                    <Skeleton height={22} width={80} style={{ borderRadius: 8 }}/>
                ) : (
                    <AmazonLink href={item?.link}>Amazon</AmazonLink>
                )}
            </div>
        </Wrapper>
    )
}

export default ProductCard;
